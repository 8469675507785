import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import DivertButton from '../../components/divert-button'
import { appConfig } from '../../config/tf-config'
import { WarningPageConfig } from '../../core/types'
import { WarningMessage } from './warning-message'

type WarningProps = {
    warningConfig: WarningPageConfig;
}

const WarningContainer = styled.div`
    text-align: left;
    padding: 20px;
    white-space: pre-wrap;
`;

export default function Warning({ warningConfig }: WarningProps) {
    const showThumb = !!warningConfig.messages.find(message => !!message.image);

    return (
      <WarningContainer>
        <Typography variant="h4" textAlign={"center"}>
          {appConfig.productName}
        </Typography>
        <img
          src={appConfig.productImage}
          width="auto"
          style={{ maxWidth: "80%", maxHeight: 300 }}
          alt={appConfig.productName}
        />
        {appConfig.description && (
          <Typography variant="body1" textAlign={"center"}>
            {appConfig.description}
          </Typography>
        )}
        <Typography variant="h6" textAlign={"center"}>
          {warningConfig.title}
        </Typography>
        {warningConfig.messages.map((message, index) => (
          <WarningMessage
            key={`warning_${index}`}
            warningMessage={message}
            showThumb={showThumb}
          />
        ))}
        <DivertButton divertButton={warningConfig.divertButton} />
      </WarningContainer>
    );
}
