import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CleanAnchor, CleanLink } from '../../components/clean-link';
import { appConfig } from '../../config/tf-config';
import { CheckListLinkItemConfig, CheckListStepsItemConfig } from '../../core/types';
import { isUrlExternal } from '../../util/util';

type CheckListItemProps = {
    index?: number,
    itemConfig: (CheckListStepsItemConfig | CheckListLinkItemConfig)
}

const CheckListBox = styled.div`
    border: 1px solid black;
    margin-top: -1px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-content: end;
    align-items: end;
`;

const CheckListContentBox = styled.div`
    align-self: center;
    display: flex;
`;

const CheckBoxWrapper = styled.div`
    align-self: center;
    padding-right: 20px;
    color: ${appConfig.theme.primaryColor};
`;

export default function CheckListItem({ index, itemConfig }: CheckListItemProps) {
    const [isCompleted, setIsCompleted] = useState<boolean>(!!itemConfig.completed);
    const isLink = 'url' in itemConfig;
    const isExternal = isLink ? isUrlExternal(itemConfig.url) : false;
    const linkTarget = isLink ? (isExternal ? itemConfig.url : "/" + itemConfig.url) : itemConfig.checkListItemId;

    const setCompleted = (state = true, checkLink = true) => {
        if (isLink || !checkLink) {
            itemConfig.completed = state;
            setIsCompleted(state);
        }
    }

    return (
      <CheckListBox>
        <CheckListContentBox>
          <CheckBoxWrapper>
            {isCompleted ? (
              <CheckCircleIcon
                fontSize="large"
                onClick={() => setCompleted(false, false)}
              />
            ) : (
              <RadioButtonUncheckedIcon
                fontSize="large"
                onClick={() => setCompleted(true, false)}
              />
            )}
          </CheckBoxWrapper>
          <div>
            <Typography variant="h6">
              {index ? index + ". " : ""}
              {itemConfig.title}
            </Typography>
            {itemConfig.description && (
              <Typography variant="body1">{itemConfig.description}</Typography>
            )}
          </div>
        </CheckListContentBox>
        {isExternal ? (
          <CleanAnchor
            key={`link_${itemConfig.checkListItemId}`}
            href={linkTarget}
          >
            <Button variant="contained" size="small" onClick={() => setCompleted()}>
              <ArrowForwardIosIcon />
            </Button>
          </CleanAnchor>
        ) : (
          <CleanLink key={`link_${itemConfig.checkListItemId}`} to={linkTarget}>
            <Button variant="contained" size="small" onClick={() => setCompleted()}>
              <ArrowForwardIosIcon />
            </Button>
          </CleanLink>
        )}
      </CheckListBox>
    );
}
