import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { CleanAnchor, CleanLink } from '../../components/clean-link';
import { LinkConfig } from '../../core/types';
import { isUrlExternal } from '../../util/util';

type LinkItemProps = {
    index?: number;
    linkItem: LinkConfig;
};

const LinkBox = styled.div`
    border: 1px solid black;
    margin-top: -1px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-content: end;
    align-items: end;
`;

export default function LinkItem({ index, linkItem }: LinkItemProps) {
    return (
        <LinkBox>
            <div>
                <Typography variant="h6">{index ? index + ". " : ""}{linkItem.title}</Typography>
                {linkItem.description && <Typography variant="body1">{linkItem.description}</Typography>}
            </div>
            <div>
                {linkItem.openAsEmbeddedPdf ?
                    <CleanLink to={linkItem.linkId}><Button size="small" variant="contained"><ArrowForwardIosIcon /></Button></CleanLink> :
                    isUrlExternal(linkItem.url) || linkItem.forceExternal ?
                        <CleanAnchor href={linkItem.url}><Button size="small" variant="contained"><ArrowForwardIosIcon /></Button></CleanAnchor> :
                        <CleanLink to={"/" + linkItem.url}><Button size="small" variant="contained"><ArrowForwardIosIcon /></Button></CleanLink>
                }
            </div>
        </LinkBox>
    );
}
