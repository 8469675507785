import { Button, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { CheckListStepsItemConfig } from '../../core/types';

type CheckListStepperProps = {
    item: CheckListStepsItemConfig
}

const StepperContainer = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const StepperTitle = styled(Typography).attrs({
    variant: "h4"
})`
    margin-bottom: 80px;
`;

const StepperImage = styled.img`
  width: 80%;
  object-fit: contain;
`;

const StepperView = styled.div`
    padding: 20px;
    text-align: justify;
    align-items: center;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
`;

export default function ChecklistStepper({ item }: CheckListStepperProps) {
    const [activeStep, setActiveStep] = useState(0);

    const next = () => {
        setActiveStep(activeStep => activeStep + 1);
    }

    const setCompleted = () => {
        item.completed = true;
        window.history.back();
    }

    return (
        <StepperContainer>
            <StepperTitle>{item.title}</StepperTitle>
            {
                item.steps.length === 0 ?
                    <Typography variant="body1">There are no steps configured for this item</Typography> :
                    <Stepper activeStep={activeStep} alternativeLabel style={{width: "100%"}}>
                        {item.steps.map((step, index) => (
                            <Step key={`step_${item.title}_${index}`} >
                                <StepButton onClick={() => setActiveStep(index)}>{step.title}</StepButton>
                            </Step>
                        ))}
                    </Stepper>
            }

            <SwipeableViews style={{ width: "100%" }} index={activeStep} onChangeIndex={(step) => setActiveStep(step)}>
                {item.steps.map(step => <StepperView>
                    {step.image && <StepperImage src={step.image} alt={step.title}/>}
                    <Typography variant="body1" style={{padding: 20}}>{step.description}</Typography>
                </StepperView>)}
            </SwipeableViews>

            {activeStep < item.steps.length - 1 && <Button variant="contained" onClick={next}>Next</Button>}
            {(activeStep === item.steps.length - 1 || item.steps.length === 0) && <Button variant="contained" onClick={setCompleted}>Finish</Button>}
        </StepperContainer>
    )
}
