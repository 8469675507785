import { Typography } from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router';
import styled from 'styled-components';
import DivertButton from '../../components/divert-button';
import { CheckListPageConfig } from '../../core/types';
import CheckListItem from './checklist-item';

type CheckListProps = {
    checkListConfig: CheckListPageConfig
}

const CheckListContainer = styled.div`
    text-align: left;
    padding: 20px;
`;

const CheckListItemContainer = styled.div`
    padding-top: 50px;
`;

export default function CheckList({ checkListConfig }: CheckListProps) {
    const location = useLocation();
    if (location.pathname.split('/').length > 2) {
        return <Outlet />;
    } else {
        return (
          <CheckListContainer>
            <Typography variant="h4" textAlign={"center"}>
              {checkListConfig.title}
            </Typography>
            <Typography variant="h5" textAlign={"center"}>
              {checkListConfig.description}
            </Typography>
            <CheckListItemContainer>
              {checkListConfig.items.map((item, index) => (
                <CheckListItem
                  key={`checkitem_${item.checkListItemId}`}
                  index={checkListConfig.showIndex ? index + 1 : undefined}
                  itemConfig={item}
                />
              ))}
            </CheckListItemContainer>
            <DivertButton divertButton={checkListConfig.divertButton} />
          </CheckListContainer>
        );
    }
}
