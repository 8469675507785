import { Typography } from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router';
import styled from 'styled-components';
import DivertButton from '../../components/divert-button';
import { LinkListPageConfig } from '../../core/types';
import LinkItem from './linkitem';

type LinkListProps = {
    linkListConfig: LinkListPageConfig
}

const LinkListContainer = styled.div`
    text-align: left;
    padding: 20px;
`;

const LinkListItemContainer = styled.div`
    padding-top: 50px;
`;


export default function LinkList({ linkListConfig }: LinkListProps) {
    const location = useLocation();
    if (location.pathname.split('/').length > 2) {
        return <Outlet />;
    } else {
        return (
          <LinkListContainer>
            <Typography variant="h4" textAlign={"center"}>
              {linkListConfig.title}
            </Typography>
            <LinkListItemContainer>
              {linkListConfig.links.map((link, index) => (
                <LinkItem
                  key={link.linkId}
                  index={linkListConfig.showIndex ? index + 1 : undefined}
                  linkItem={link}
                />
              ))}
            </LinkListItemContainer>
            <DivertButton divertButton={linkListConfig.divertButton} />
          </LinkListContainer>
        );
    }
}
