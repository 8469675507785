import { Button, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { DivertButtonConfig } from '../core/types'
import { isUrlExternal } from '../util/util'
import { CleanAnchor, CleanLink } from './clean-link'

type DivertButtonProps = {
    divertButton?: DivertButtonConfig
}

const ButtonContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 70%;
`

export default function DivertButton({ divertButton }: DivertButtonProps) {
    return (
      <>
        {divertButton && (
          <ButtonContainerWrapper>
            <ButtonContainer>
              {divertButton.description && (
                <Typography variant="caption" style={{ fontStyle: "italic" }}>
                  {divertButton.description}
                </Typography>
              )}
              {isUrlExternal(divertButton.url) || divertButton.forceExternal ? (
                <CleanAnchor href={divertButton.url}>
                  <Button variant="contained">{divertButton.content}</Button>
                </CleanAnchor>
              ) : (
                <CleanLink to={divertButton.url}>
                  <Button style={{ width: "80%" }} variant="contained">
                    <p>{divertButton.content}</p>
                  </Button>
                </CleanLink>
              )}
            </ButtonContainer>
          </ButtonContainerWrapper>
        )}
        {!divertButton && <></>}
      </>
    );
}
