import { AppConfig } from "../core/types";

export const appConfig: AppConfig = {
  projectId: "if-tutorial-flow",
  companyName: "Intelligent Fridges",
  logo: "https://i.imgur.com/3cye7sc.png",
  productName: "Deluxe Intelligent Fridge",
  productImage: "https://intelligentfridges.com/img/fridge-4.png",
  landingPageId: "landing",
  description:
    "Thank you for your purchase, this tool will get you started with the intelligent fridge.",
  theme: {
    font: "Gotham",
    primaryColor: "#4A95AF",
    secondaryColor: "#F2F2F2",
  },
  pages: [
    {
      pageId: "landing",
      title: "Warnings",
      type: "warning",
      hiddenInMenu: true,
      messages: [
        {
          title: "IMPORTANT:",
          image: "https://i.imgur.com/2Ys5GhC.png",
          description:
            "Before switching on the cooling system, it is mandatory that the appliance is in a vertical - stand upright - position for at least 8 hours",
        },
        {
          title: "WARNING:",
          image: "https://i.imgur.com/0DCPgkB.png",
          description:
            "Keep all ventilation openings in the appliance enclosure clear of any obstruction or open in the structure for building-in",
        },
        {
          title: "CHECK:",
          image: "/files/caution.png",
          description:
            "Please check the product model & serial number.\nSomething wrong? Please contact support.",
        },
      ],
      divertButton: {
        content: "START INSTALLATION",
        url: "welcome",
      },
    },
    {
      pageId: "welcome",
      title: "Welcome",
      description: "Get started",
      headerTitle: "Start",
      type: "checklist",
      showIndex: true,
      items: [
        {
          checkListItemId: "tutorial",
          title: "Tutorial",
          description: "Start with the installation guide of the fridge",
          steps: [
            {
              title: "",
              image: "/files/IF_tutorial_step1.png",
              description:
                "First things first, remove the packaging (if not done yet).\n\nCheck the fridge and the transport seals on any damages. Someting wrong? please contact support.",
            },
            {
              title: "",
              image: "/files/IF_tutorial_step2.png",
              description:
                "Then it is time to move the fridge to its location.\n\nMove the fridge using the wheels. Make sure the fridge vents at the back and the top are not blocked. The fridge should be placed on a flat and firm surface.",
            },
            {
              title: "",
              image: "/files/IF_tutorial_step3.png",
              description:
                "Make sure the wheels are locked after moving the fridge.\n\nThe 2 middle wheels do not have a lock, lock the other wheels by clicking down the metal resistor so the fridge stays in its position.",
            },
            {
              title: "",
              image: "/files/IF_tutorial_step4.png",
              description:
                "Next is clicking the three baseboards at the bottom of the fridge housing!\n\nThe three baseboards can be found on top of the fridge housing (1x front baseboard & 1x side baseboards). Start by connecting the sides to the fridge housing, make sure (1) is on top and (2) pointing towards the front of the fridge. Then connect the front baseboard, via the click system the front baseboards’ (3) will connect to the side baseboards’ (2).",
            },
            {
              title: "",
              image: "/files/IF_tutorial_step5.png",
              description:
                "Get the fridge ready by connecting the power cord & ethernet cable (if ethernet port is available).\n\nPower the fridge by plugging in the black power cord, which is coming from the top of the fridge, into the power socket. The owner is responsible for providing power extensions when needed. Then connect the ethernet cable when a port is available (this is optional).",
            },
            {
              title: "",
              image: "/files/IF_tutorial_step6.png",
              description:
                "Now, Let's turn on the fridge with the controller.\n\nPull up the front plate of the fridge housing and activate the controller using the ‘Power on (1)’ button of the fridge controller. Press ‘Set (2)’ to set the temperature baseline of the fridge to 01 degrees, using the arrows (3). Confirm the Setpoint temperature by pressing ‘Set (4)’ again.",
            },
            {
              title: "",
              image: "",
              description:
                "And that’s it for the installation guide!\n\nPlease take a picture of the current state of the fridge and send it to support@intelligentfridges.com. If a wifi connection is desired, go to the installation guide of the Network settings.",
            },
          ],
        },
        {
          checkListItemId: "network",
          title: "Network",
          description:
            "If desired, connect your fridge with the wifi (admin account required)",
          steps: [
            {
              title: "",
              image: "/files/IF_Network_step1.png",
              description:
                "First, stand closely to the fridge.\n\nIn order to connect to the local network, we have to connect to the wifi of the router, this can only be done when closely standing at the fridge location. ",
            },
            {
              title: "",
              image: "/files/IF_Network_step2.png",
              description:
                "Then connect to wifi of the router of the fridge.\n\nGo to wifi settings of your smartphone and connect to the wifi router of the fridge (Husky-IF-markXX). Password is husky-wireless. ",
            },
            {
              title: "",
              image: "",
              description:
                "Make sure you will get a pop-up screen that will lead you to a webpage.\n\nWithin this webpage, configure the wifi of the fridge to the local network of the location.",
            },
          ],
        },
      ],
    },
    {
      pageId: "documents",
      title: "Documents",
      type: "linklist",
      showIndex: false,
      links: [
        {
          linkId: "manual",
          title: "Manual",
          description: "Content",
          url: "/files/User_Manual_1_-_Installation_and_Service_V1.0.pdf",
          openAsEmbeddedPdf: true,
        },
        {
          linkId: "dictionary",
          title: "Dictionary",
          description: "Content",
          url: "https://google.com",
        },
        {
          linkId: "warranty",
          title: "Warranty",
          description: "Content",
          url: "https://google.com",
        },
      ],
    },
    {
      pageId: "support",
      title: "Support",
      type: "markdown",
      alignText: "left",
      body: "# Support\n### Contact\n Something wrong? Please contact us by mail with your problem. \nMake sure to add your fridge ID (MarkX, IF-00XXX) and some video's and pictures of the problem. By doing so, we can help you in the best way possible. You can find this number on the sticker at the inside of the fridge. We will reach out to you within 1 day. \n For urgent situations, call our emergency phone number below. Our workhours are from 8 AM till 6 PM (CET). \n\n[support@intelligentfridges.com](mailto:support@intelligentfridges.com)\n\n[+31 85 13 02 903](tel:+31851302903)\n\nAlso check out our FAQ page where you can find your answers as well.",
      divertButton: {
        content: "GO TO FAQ",
        url: "https://google.com",
      },
    },
  ],
};
