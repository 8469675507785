import { createTheme } from "@mui/material/styles";
import { appConfig } from "./config/tf-config";

export const theme = createTheme({
  typography: {
    fontFamily: appConfig.theme.font ? appConfig.theme.font : "Roboto",
  },
  palette: {
    primary: {
      main: appConfig.theme.primaryColor,
      contrastText: appConfig.theme.contrastText
        ? appConfig.theme.contrastText
        : "#F2F2F2",
    },
    secondary: {
      main: appConfig.theme.secondaryColor,
      contrastText: appConfig.theme.secondaryContrastText
        ? appConfig.theme.secondaryContrastText
        : "#F2F2F2",
    },
  },
});
