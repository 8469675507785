import { Button, Typography } from "@mui/material";
import { useLocation } from "react-router";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { appConfig } from "../config/tf-config";
import { CleanLink } from "./clean-link";

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${appConfig.theme.primaryColor};
    height: 80px;
    width: 100%;
    align-items: center;
    align-content: center;
`;

const HeaderLinks = styled.nav`
    display: flex;
    flex-direction: row;
    align-self: center;
    padding-right: 10px;
`;

const LogoImage = styled.img.attrs(props => ({
    src: appConfig.logo,
    alt: appConfig.companyName
}))`
    padding-left: 20px;
    height: 30px;
`;

type ButtonProps = {
    children: React.ReactNode
};

const NavButton: FunctionComponent<ButtonProps> = ({ children }) => (
  <Button color="secondary" style={{ textTransform: "none" }}>
    <Typography
      variant="button"
      style={{ fontWeight: "200", textTransform: "none", lineHeight: 1, textAlign: "center", verticalAlign: "center" }}
    >
      {children}
    </Typography>
  </Button>
);

const BoldNavButton: FunctionComponent<ButtonProps> = ({ children }) => (
  <NavButton>
    <Typography
      variant="inherit"
      style={{ fontWeight: "700" }}
    >
      {children}
    </Typography>
  </NavButton>
);

export default function Header() {
    const location = useLocation().pathname.split('/')[1];

    const items = appConfig.pages.filter(page => !page.hiddenInMenu).map(page => {
        return {
            title: page.headerTitle ? page.headerTitle : page.title,
            value: page.pageId === appConfig.landingPageId ? "/" : page.pageId,
        };
    });

    return (
        <HeaderContainer>
            <CleanLink to="/"><LogoImage /></CleanLink>
            <HeaderLinks>
                {items.map(item => <CleanLink key={`header_link_${item.value}`} to={item.value} >
                    {location === item.value ?
                        <BoldNavButton>{item.title}</BoldNavButton> :
                        <NavButton>{item.title}</NavButton>
                    }
                </CleanLink>)}
            </HeaderLinks>
        </HeaderContainer>
    )
}
