import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import DivertButton from '../components/divert-button'
import { MarkdownPageConfig } from '../core/types'
import { appConfig } from "../config/tf-config";

type MarkdownProps = {
    markdownConfig: MarkdownPageConfig
}

const MarkdownContainer = styled.div`
  padding: 20px;
  align-self: center;
  font-family: ${appConfig.theme.font ? appConfig.theme.font : "Roboto"};
  line-height: 1.1;
  text-align: left;
  & > h1 {
    margin: 0px;
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.235;
  }
  & > h3 {
    margin: 0px;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
  }
  & > p {
    text-align: justify;
  }
  * > a {
    text-decoration: none;
  }
`;

export default function Markdown({ markdownConfig }: MarkdownProps) {
    return <MarkdownContainer style={{ textAlign: markdownConfig.alignText }}>
        <ReactMarkdown children={markdownConfig.body} />
        <DivertButton divertButton={markdownConfig.divertButton} />
    </MarkdownContainer>
}
