import { Link } from "react-router-dom";
import styled from "styled-components";

export const CleanLink = styled(Link)`
    text-decoration: none;
`;

export const CleanAnchor = styled.a.attrs(props => ({
    target:"_blank",
    rel:"noreferrer",
}))`
    text-decoration: none;
`;
