import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { WarningMessageConfig } from '../../core/types';

type WarningMessageProps = {
    warningMessage: WarningMessageConfig;
    showThumb: boolean;
};

const WarningMessageContainer = styled.div`
    margin-top: 20px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const ThumbnailContainer = styled.div`
    padding-right: 5px;
    width: 20%;
    align-self: center;
    justify-content: center;
    align-content: center;
`;

const WarningTextContainer = styled.div`
    flex-direction: row;
    display: flex;
`
export const WarningMessage: FunctionComponent<WarningMessageProps> = ({ warningMessage, showThumb }: WarningMessageProps) => {
    const messageWidth = showThumb ? "80%" : "100%";
    return (
        <WarningMessageContainer>
            {showThumb && <ThumbnailContainer>
                {warningMessage.image ?
                    <img src={warningMessage.image} alt={warningMessage.title} width='80%' /> :
                    <div style={{ width: "100%" }}></div>}
            </ThumbnailContainer>}
            <WarningTextContainer style={{ width: messageWidth }}>
                <Typography variant="body1"><span style={{ color: "red" }}><b>{warningMessage.title}</b></span> <span>{warningMessage.description}</span></Typography>
            </WarningTextContainer>
        </WarningMessageContainer>
    );
};
