import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import DivertButton from '../../components/divert-button';
import useWindowDimensions from '../../hooks/use-window-dimensions';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type doc = {
    numPages: number
}

type PDFViewerProps = {
    file: string
}

const DocumentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PageNavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
`;

export default function PDFViewer({ file }: PDFViewerProps) {
    const { width } = useWindowDimensions();
    const [numPages, setNumPages] = useState<number | undefined>(undefined);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }: doc) => {
        setNumPages(numPages);
    }

    return (
        <DocumentContainer style={{ width: width }}>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page width={width} pageNumber={pageNumber} />
            </Document>
            {numPages && <>
                <PageNavigationContainer>
                    <IconButton onClick={() => setPageNumber(pageNumber => pageNumber - 1)} disabled={pageNumber <= 1}><ArrowBackIosIcon /></IconButton>
                    <Typography variant="body1">Page {pageNumber} of {numPages}</Typography>
                    <IconButton onClick={() => setPageNumber(pageNumber => pageNumber + 1)} disabled={pageNumber >= numPages}><ArrowForwardIosIcon /></IconButton>
                </PageNavigationContainer>
            </>
            }
            <DivertButton divertButton={{content: "Open in browser", description: "Problems viewing document?", url: file, forceExternal: true}} />
        </DocumentContainer>
    );
}
