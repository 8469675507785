import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router';
import styled from 'styled-components';
import Header from '../components/header';
import { appConfig } from '../config/tf-config';
import { AnyPageConfig } from '../core/types';
import CheckList from './checklist/checklist';
import ChecklistStepper from './checklist/checklist-stepper';
import LinkList from './linklist/linklist';
import PDFViewer from './linklist/pdfviewer';
import Markdown from './markdown';
import Warning from './warning/warning';

type PageProps = {
    pageConfig: AnyPageConfig
}

const Page: FunctionComponent<PageProps> = ({ pageConfig }: PageProps) => {
    switch (pageConfig.type) {
        case 'checklist':
            return <CheckList checkListConfig={pageConfig} />
        case 'linklist':
            return <LinkList linkListConfig={pageConfig} />
        case 'markdown':
            return <Markdown markdownConfig={pageConfig} />
        case 'warning':
            return <Warning warningConfig={pageConfig} />
    }
}

const SubRoutes = (pageConfig: AnyPageConfig) => {
    switch (pageConfig.type) {
        case 'checklist':
            return pageConfig.items.map((item, index) => {
                if ('steps' in item) {
                    return <Route key={`route_${item.checkListItemId}_${index}`} path={item.checkListItemId} element={<ChecklistStepper item={item} />} />;
                }
                return <></>;
            });
        case 'linklist':
            return pageConfig.links.filter(link => link.openAsEmbeddedPdf).map((item, index) => <Route key={`route_${item.linkId}_${index}`} path={item.linkId} element={<PDFViewer file={item.url} />} />);
        default:
            return <></>;
    }
}

const AppBase = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default function Template() {
    return (
        <AppBase>
            <Header />
            <Routes>
                {
                    appConfig.pages.map((page, index) => {
                        const path = page.pageId === appConfig.landingPageId ? '' : page.pageId;
                        return <Route key={`route_${index}`} path={path} element={<Page key={`page_${path}`} pageConfig={page} />}>
                            {SubRoutes(page)}
                        </Route>
                    })
                }
            </Routes>
        </AppBase>
    )
}
